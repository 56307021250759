var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-2"},[(!_vm.inEdit)?[_c('PreviewAnonymisationRules',{attrs:{"baseRule":_vm.baseRule,"numberOfRules":_vm.rules.length,"sample":_vm.sample,"exampleValueCalculator":_vm.getExampleValue},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Interval")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" The field values will likely be generalized to arithmetic intervals of some generalization level. The anonymisation algorithm will try to stay at the lowest possible level of generalization. ")]},proxy:true},{key:"rule",fn:function(ref){
var index = ref.index;
return [_c('IntervalRule',{attrs:{"rule":_vm.rules[index]}})]}},{key:"exampleCell",fn:function(ref){
var exampleValue = ref.exampleValue;
var index = ref.index;
return [_c('div',{staticClass:"flex flex-row"},[(exampleValue.from === null)?_c('AnonymisationNilValue'):_c('span',{staticClass:"text-primary-800"},[(index === 0)?[_vm._v(_vm._s(Number(exampleValue.from.toFixed(3))))]:[_vm._v(_vm._s(Math.round(exampleValue.from)))]],2),(exampleValue.to)?[_vm._v(" : "),_c('span',{staticClass:"text-primary-800"},[_vm._v(_vm._s(Math.round(exampleValue.to)))])]:_vm._e()],2)]}}],null,false,1935269808)})]:[(_vm.selectedField.options.leveling === 'auto')?_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex flex-row space-x-6"},[_c('div',{staticClass:"my-auto text-sm text-neutral-700"},[_vm._v("Interval Value")]),_c('div',{staticClass:"relative w-40"},[_c('ValidationProvider',{attrs:{"rules":"required|not_equal_to:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.selectedField.options.levels[0].interval),expression:"selectedField.options.levels[0].interval",modifiers:{"number":true}}],staticClass:"block w-full sm:text-sm sm:leading-5",class:{
                                'border-danger-700': errors.length > 0,
                                'pr-4': _vm.field.type !== 'double' && errors.length > 0,
                                'pr-8': _vm.field.type === 'double' && errors.length > 0,
                            },attrs:{"type":"number","step":_vm.field.type !== 'double' ? null : 'any'},domProps:{"value":(_vm.selectedField.options.levels[0].interval)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedField.options.levels[0], "interval", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length > 0)?_c('InputErrorIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getIntervalErrorMessage(_vm.selectedField.options.levels[0].interval)),expression:"getIntervalErrorMessage(selectedField.options.levels[0].interval)"}]}):_vm._e()]}}],null,false,2388378047)})],1)]),_c('div',{staticClass:"text-xs italic text-neutral-600"},[_vm._v(" The initial interval value. This value will be doubled at each generalisation level. ")])]):_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',{staticClass:"flex flex-col space-y-2"},[_vm._m(0),_vm._l((_vm.selectedField.options.levels),function(level,levelIndex){return _c('div',{key:levelIndex,staticClass:"flex flex-row items-center space-x-4"},[_c('div',{staticClass:"w-12 text-sm"},[_vm._v("Level "+_vm._s(levelIndex + 1))]),_c('div',{staticClass:"relative w-40"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('input',{staticClass:"block w-full py-1 pl-3 pr-1 form-input sm:text-sm sm:leading-5",class:{
                                    'border-danger-700': errors.length > 0,
                                    'pr-4': _vm.field.type !== 'double' && errors.length > 0,
                                    'pr-8': _vm.field.type === 'double' && errors.length > 0,
                                },attrs:{"type":"number","step":_vm.field.type !== 'double' ? null : 'any'},domProps:{"value":level.interval},on:{"input":function($event){return _vm.setLevelInterval(levelIndex, $event)}}}),(errors.length > 0)?_c('InputErrorIcon'):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('button',{staticClass:"text-neutral-600 hover:text-neutral-700",on:{"click":function($event){return _vm.removeCustomLevel(levelIndex)}}},[_c('TrashIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove Group'),expression:"'Remove Group'"}],staticClass:"w-4 h-4"})],1)])])})],2),_c('div',{staticClass:"flex flex-col items-start"},[(_vm.selectedField.options.leveling === 'custom')?_c('button',{staticClass:"items-center py-1 pl-2 pr-3 text-xs font-medium leading-5 text-white uppercase transition duration-150 ease-in-out border rounded border-neutral-600 hover:border-neutral-500 bg-neutral-600 hover:bg-neutral-500 focus:outline-none focus:ring-neutral focus:border-neutral-700 active:bg-neutral-700 disabled:bg-neutral-400",attrs:{"type":"button"},on:{"click":_vm.addGeneralisationLevel}},[_vm._v(" Add Generalization Level ")]):_vm._e()])]),_c('HandleNullValuesFieldSection',{attrs:{"field":_vm.selectedField,"isMultiple":_vm.mappingField.multiple},scopedSlots:_vm._u([{key:"replaceWith",fn:function(ref){
                                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.selectedField.options.nullValues.replaceWith),expression:"selectedField.options.nullValues.replaceWith",modifiers:{"number":true}}],staticClass:"py-1 text-sm disabled:bg-neutral-300",class:{
                        'border-danger-700': errors.length > 0,
                        'pr-4': _vm.field.type !== 'double' && errors.length > 0,
                        'pr-8': _vm.field.type === 'double' && errors.length > 0,
                    },attrs:{"type":"number","step":_vm.field.type !== 'double' ? null : 'any',"disabled":_vm.field.options.nullValues.keep},domProps:{"value":(_vm.selectedField.options.nullValues.replaceWith)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedField.options.nullValues, "replaceWith", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]}}]),model:{value:(_vm.selectedField.options.nullValues),callback:function ($$v) {_vm.$set(_vm.selectedField.options, "nullValues", $$v)},expression:"selectedField.options.nullValues"}})]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"w-12 text-xs tracking-wide uppercase text-neutral-600"},[_vm._v("Level")]),_c('div',{staticClass:"w-40 text-xs tracking-wide uppercase text-neutral-600"},[_vm._v("Interval")])])}]

export { render, staticRenderFns }