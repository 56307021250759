var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-2"},[(!_vm.inEdit)?[_c('PreviewAnonymisationRules',{attrs:{"baseRule":_vm.baseRule,"numberOfRules":_vm.rules.length,"sample":_vm.sample,"exampleValueCalculator":_vm.getExampleValue},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Numerical Group")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" The field values will likely be generalized to numerical groups of some generalization level. The anonymisation algorithm will try to stay at the lowest possible level of generalization. ")]},proxy:true},{key:"rule",fn:function(ref){
var index = ref.index;
return [_c('NumericalGroupRule',{attrs:{"rule":_vm.rules[index]}})]}},{key:"exampleCell",fn:function(ref){
var exampleValue = ref.exampleValue;
var index = ref.index;
return [_c('div',{staticClass:"flex flex-row"},[(_vm.isNil(exampleValue))?_c('AnonymisationNilValue'):(index === 0 && !_vm.isNil(exampleValue.from))?[_vm._v(_vm._s(Number(exampleValue.from.toFixed(3))))]:[(_vm.isNil(exampleValue))?_c('AnonymisationNilValue'):[_vm._v(_vm._s(exampleValue))]]],2)]}}],null,false,3951422875)})]:[_vm._l((_vm.selectedField.options.levels),function(level,levelIndex){return _c('div',{key:levelIndex,staticClass:"flex flex-col p-4 space-y-2 border rounded-md border-neutral-300 bg-neutral-100"},[(_vm.selectedField.options.leveling === 'custom')?_c('div',{staticClass:"text-sm font-bold text-neutral-600"},[_vm._v(" Level "+_vm._s(levelIndex + 1)+" ")]):_vm._e(),_vm._m(0,true),_vm._l((level),function(group,groupIndex){return _c('div',{key:groupIndex,staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex flex-row items-center space-x-6"},[_c('div',{staticClass:"relative w-32"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.levelInputs[levelIndex][groupIndex].from),expression:"levelInputs[levelIndex][groupIndex].from"}],staticClass:"block w-full py-1 pl-3 pr-1 sm:text-sm sm:leading-5",class:{
                                    'border-danger-700': errors.length > 0,
                                    'pr-4': _vm.field.type !== 'double' && errors.length > 0,
                                    'pr-8': _vm.field.type === 'double' && errors.length > 0,
                                },attrs:{"type":"number","step":_vm.field.type !== 'double' ? null : 'any'},domProps:{"value":(_vm.levelInputs[levelIndex][groupIndex].from)},on:{"blur":_vm.setLevels,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.levelInputs[levelIndex][groupIndex], "from", $event.target.value)}}}),(errors.length > 0)?_c('InputErrorIcon'):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"relative w-32"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.levelInputs[levelIndex][groupIndex].to),expression:"levelInputs[levelIndex][groupIndex].to"}],staticClass:"block w-full py-1 pl-3 pr-1 sm:text-sm sm:leading-5",class:{
                                    'border-danger-700': errors.length > 0,
                                    'pr-4': _vm.field.type !== 'double' && errors.length > 0,
                                    'pr-8': _vm.field.type === 'double' && errors.length > 0,
                                },attrs:{"type":"number","step":_vm.field.type !== 'double' ? null : 'any'},domProps:{"value":(_vm.levelInputs[levelIndex][groupIndex].to)},on:{"blur":_vm.setLevels,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.levelInputs[levelIndex][groupIndex], "to", $event.target.value)}}}),(errors.length > 0)?_c('InputErrorIcon'):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"relative w-32"},[_c('ValidationProvider',{attrs:{"rules":{
                                required_if: _vm.hasLabels,
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.levelInputs[levelIndex][groupIndex].label),expression:"levelInputs[levelIndex][groupIndex].label"}],staticClass:"block w-full py-1 pl-3 pr-1 sm:text-sm sm:leading-5",class:{
                                    'border-danger-700': errors.length > 0,
                                },attrs:{"type":"text","placeholder":_vm.getLabelPlaceholder(_vm.levelInputs[levelIndex][groupIndex])},domProps:{"value":(_vm.levelInputs[levelIndex][groupIndex].label)},on:{"blur":_vm.setLevels,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.levelInputs[levelIndex][groupIndex], "label", $event.target.value)}}}),(errors.length > 0)?_c('InputErrorIcon'):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('button',{staticClass:"text-neutral-600 hover:text-neutral-700",on:{"click":function($event){return _vm.removeNumericalGroup(levelIndex, groupIndex)}}},[_c('TrashIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove Group'),expression:"'Remove Group'"}],staticClass:"w-4 h-4"})],1)])]),_c('div',{staticClass:"flex flex-col items-center"},[(
                            _vm.selectedField.options.levels &&
                            groupIndex === _vm.selectedField.options.levels[levelIndex].length - 1
                        )?_c('button',{staticClass:"text-neutral-600 hover:text-neutral-700",on:{"click":function($event){return _vm.addNumericalGroup(levelIndex)}}},[_c('PlusCircleIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Add Group'),expression:"'Add Group'"}],staticClass:"w-6 h-6"})],1):_vm._e()])])})],2)}),_c('div',{staticClass:"flex flex-col items-center"},[(_vm.selectedField.options.leveling === 'custom')?_c('button',{staticClass:"inline-flex items-center py-1 pl-2 pr-3 text-xs font-medium leading-5 text-white uppercase transition duration-150 ease-in-out border rounded border-neutral-600 hover:border-neutral-500 bg-neutral-600 hover:bg-neutral-500 focus:outline-none focus:ring-neutral focus:border-neutral-700 active:bg-neutral-700 disabled:bg-neutral-400",attrs:{"type":"button"},on:{"click":_vm.addGeneralisationLevel}},[_vm._v(" Add Generalization Level ")]):_vm._e()]),(_vm.validationMessage)?_c('div',{staticClass:"p-2 text-xs border rounded-md bg-danger-100 border-danger-800 text-danger-800"},[_c('div',{staticClass:"flex flex-row items-start space-x-1"},[_c('span',{staticClass:"font-semibold whitespace-nowrap"},[_vm._v(_vm._s(_vm.validationMessage.title)+":")]),_c('span',[_vm._v(_vm._s(_vm.validationMessage.message))])]),_c('ValidationProvider',{attrs:{"rules":"required"}},[_c('input',{attrs:{"type":"hidden"},domProps:{"value":null}})])],1):_vm._e(),_vm._m(1),_c('HandleNullValuesFieldSection',{attrs:{"field":_vm.selectedField,"isMultiple":_vm.mappingField.multiple},scopedSlots:_vm._u([{key:"replaceWith",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.selectedField.options.nullValues.replaceWith),expression:"selectedField.options.nullValues.replaceWith",modifiers:{"number":true}}],staticClass:"py-1 text-sm disabled:bg-neutral-300",class:{
                        'border-danger-700': errors.length > 0,
                        'pr-4': _vm.field.type !== 'double' && errors.length > 0,
                        'pr-8': _vm.field.type === 'double' && errors.length > 0,
                    },attrs:{"type":"number","step":_vm.field.type !== 'double' ? null : 'any',"disabled":_vm.field.options.nullValues.keep},domProps:{"value":(_vm.selectedField.options.nullValues.replaceWith)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedField.options.nullValues, "replaceWith", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]}}]),model:{value:(_vm.selectedField.options.nullValues),callback:function ($$v) {_vm.$set(_vm.selectedField.options, "nullValues", $$v)},expression:"selectedField.options.nullValues"}})]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center space-x-6"},[_c('div',{staticClass:"w-32 text-xs tracking-wide uppercase text-neutral-600"},[_vm._v("From")]),_c('div',{staticClass:"w-32 text-xs tracking-wide uppercase text-neutral-600"},[_vm._v("To")]),_c('div',{staticClass:"w-32 text-xs tracking-wide uppercase text-neutral-600"},[_vm._v("Label")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs italic text-neutral-600"},[_c('span',{staticClass:"font-bold"},[_vm._v("Note:")]),_vm._v(" Label fields are optional (if all blank). Leaving all fields blank, the anonymisation algorithm will give labels in the form of 'from-to' (e.g. 0-10). You can pass your own labels, which in this case all fields become required. ")])}]

export { render, staticRenderFns }